import { TitleContainer, TitleContent } from './PageTitleStyle';

interface Props {
  title: string;
}

const PageTitle = (props: Props) => {
  return (
    <TitleContainer>
      <TitleContent>{props.title}</TitleContent>
    </TitleContainer>
  );
};

export default PageTitle;
