import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/PageTitle/PageTitle';
import {
  BodyContainer,
  PageWrapperFAQ,
} from '../../styles/pageStyles/commonStyle';

import Head from 'next/head';

import axios from 'axios';
import { useEffect } from 'react';
import { PageDataInterface } from '../../Interface/PageDataInterface';
import { apiConstants } from '../../constants/apiConstants';
import { getUserInfoSSR } from '../../utils/cf';

interface Props {
  meta: PageDataInterface;
}

const toggleChildrenVisibility = (element: any) => {
  if (element.classList.contains('hidden')) {
    element.classList.remove('hidden');
  } else {
    element.classList.add('hidden');
  }
};

const PrivacyPolicy = ({ meta }: Props) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      const d = document;
      const liHeadings = d.querySelectorAll(
        'article > ul > li:not(.ql-indent-1):not(.ql-indent-2)'
      );
      const liContents = d.querySelectorAll(
        'article > ul > li.ql-indent-1, article > ul > li.ql-indent-2'
      );

      liHeadings.forEach((btn: any) => {
        btn.style.cursor = 'pointer';
        btn.addEventListener('click', function () {
          const nextSibling = this.nextElementSibling;
          toggleChildrenVisibility(nextSibling);
        });
      });

      liContents.forEach((item: any) => {
        item.style.cursor = 'pointer';
        item.addEventListener('click', function (e: any) {
          e.stopPropagation();
          const parentUl = item.closest('ul');
          toggleChildrenVisibility(parentUl);
        });
      });

      const wrapInUlTags = () => {
        const indentElements = d.querySelectorAll(
          'article > ul > li.ql-indent-1, article > ul > li.ql-indent-2'
        );
        let ul: HTMLUListElement | null = null;

        indentElements.forEach((el: any, index: number) => {
          const nextEl = indentElements[index + 1];
          if (!ul || !ul.contains(el.previousElementSibling)) {
            ul = d.createElement('ul');
            ul.classList.add('hidden'); // Add hidden class by default
            el.parentNode.insertBefore(ul, el);
          }
          ul.appendChild(el);

          // If the next element is not .ql-indent-2, close the current ul
          if (
            nextEl &&
            !nextEl.classList.contains('ql-indent-2') &&
            el.classList.contains('ql-indent-2')
          ) {
            ul = null;
          }
        });
      };

      wrapInUlTags();
    }
  }, []);

  return (
    <div>
      {meta ? (
        <Head>
          <title>{'Frequently Asked Questions | Driffle'}</title>
          {meta.featured_image ? (
            <link rel="icon" type="image/png" href={meta?.featured_image} />
          ) : (
            ''
          )}
          <meta name="description" content={meta?.meta_description} />
          <link rel="canonical" href={`https://driffle.com/${meta?.slug}`} />
          <meta
            httpEquiv="last-modified"
            content={
              meta.updated
                ? new Date(meta?.updated).toDateString()
                : new Date('2021-12-13T04:51:17.000Z').toDateString()
            }
          />
        </Head>
      ) : (
        ''
      )}
      <Header />
      <Navbar />
      <BodyContainer>
        <PageTitle title="Frequently Asked Questions" />
        <PageWrapperFAQ>
          <article
            dangerouslySetInnerHTML={{
              __html: meta && meta.content ? meta.content : '',
            }}
          ></article>
        </PageWrapperFAQ>
      </BodyContainer>
      <Footer />
    </div>
  );
};

export const getServerSideProps = async (context: any) => {
  const REGION = process.env.REGION ?? '';
  const url =
    apiConstants.ssrServer.server.replace('[--REGION--]', `${REGION}`) +
    '/public/pages/faq';

  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    let response = await axios.get(url);
    //  const data = (await axios.get(url)).data;
    const status = response.status;

    if (status == 404 || status == 400 || status == 500) {
      return {
        notFound: true,
      };
    }

    return {
      props: { meta: response.data.data, reduxInitialState: userInfo },
    };
  } catch (err: any) {
    return {
      notFound: true,
    };
  }
};

export default PrivacyPolicy;
